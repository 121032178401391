<template>
    <div id="build-details-form">
        <div class="top">
            <div class="top__left">
                <h2 class="mb-3">
                    Setup & Connect Microsoft Assets
                </h2>
                <p class="desc">
                    Looking good! In order to run Microsoft's Automotive Inventory Ads program you'll need to,
                    first, setup all the necessary asset dependencies which they require to run the program.
                    On the next step we'll walk you through the process of setting up the ads as well! 🙌
                </p>
            </div>
            <div class="top__right">
                <div>
                    <action-button
                        class="icon"
                        width="45"
                        height="45"
                        href="https://support.buyerbridge.com/knowledge/how-to-setup-dependencies-for-microsoft-automotive-inventory-ads-aia"
                        target="_blank"
                        icon="book"
                        :color="colors.blue"
                        @click.prevent="">
                        <div class="dark-word">
                            READ OUR GUIDE
                        </div>
                    </action-button>
                </div>
            </div>
        </div>

        <v-form
            ref="form"
            v-model="formValid">
            <div class="build-details">
                <loader v-if="loading" />
                <div v-else>
                    <div
                        v-if="errorMessage"
                        class="err-message">
                        {{ errorMessage }}
                    </div>

                    <!-- AD ACCOUNT -->
                    <div>
                        <h2>Ad Account</h2>
                        <div class="block">
                            <div class="block__info">
                                <p class="desc">
                                    Use the guide to the right to walk through the process of setting
                                    up a Microsoft ads account and enter the account ID once you're finished
                                </p>
                            </div>
                            <div
                                v-if="!adAccountComplete"
                                class="block__select">
                                <v-text-field
                                    v-model="accountId"
                                    label="AD ACCOUNT ID (OPTIONAL)"
                                    placeholder="Enter Account ID..."
                                    class="styled-field" />
                                <div class="small-desc">
                                    Entering the Ad Account ID now is optional but will make it easier
                                    to activate future Microsoft features if you have it now
                                </div>
                            </div>
                            <div v-if="adAccountComplete">
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div class="block__icons">
                                <action-button
                                    v-if="!adAccountComplete"
                                    class="icon"
                                    width="25"
                                    height="25"
                                    href="https://support.buyerbridge.com/knowledge/how-to-setup-dependencies-for-microsoft-automotive-inventory-ads-aia#create-microsoft-ads-account"
                                    target="_blank"
                                    icon="book"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        GUIDE
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>

                    <!-- PIXEL -->
                    <div class="mt-3">
                        <h2>Pixel</h2>
                        <div class="block">
                            <div class="block__info">
                                <p class="desc">
                                    Use the guide to the right to walk through the process of setting
                                    up a Microsoft ads account and enter the account ID once you're finished.
                                </p>
                            </div>
                            <div
                                v-if="!pixelComplete"
                                class="block__select">
                                <v-text-field
                                    v-model="pixelId"
                                    label="PIXEL ID (OPTIONAL)"
                                    placeholder="Enter Pixel ID..."
                                    class="styled-field" />
                                <div class="small-desc">
                                    Entering the Pixel ID now is optional but will make it easier
                                    to activate future Microsoft features if you have it now
                                </div>
                            </div>
                            <div v-if="pixelComplete">
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div class="block__icons">
                                <action-button
                                    v-if="!pixelComplete"
                                    class="icon"
                                    width="25"
                                    height="25"
                                    href="https://support.buyerbridge.com/knowledge/how-to-setup-dependencies-for-microsoft-automotive-inventory-ads-aia#create-microsoft-pixel"
                                    target="_blank"
                                    icon="book"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        GUIDE
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>

                    <!-- CATALOG -->
                    <div class="mt-3">
                        <h2>Catalog</h2>
                        <div class="block">
                            <div class="block__info">
                                <p class="desc">
                                    Use the guide to the right to walk through the process of setting up a
                                    Microsoft ads vehicle catalog and enter its ID once you're finished.
                                </p>
                            </div>
                            <div
                                v-if="!catalogComplete"
                                class="block__select">
                                <v-text-field
                                    v-model="catalogId"
                                    label="CATALOG ID (OPTIONAL)"
                                    placeholder="Enter Catalog ID..."
                                    class="styled-field" />
                                <div class="small-desc">
                                    Entering the Catalog ID now is optional but will make it easier
                                    to activate future Microsoft features if you have it now
                                </div>
                            </div>
                            <div v-if="catalogComplete">
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div class="block__icons">
                                <action-button
                                    v-if="!catalogComplete"
                                    class="icon"
                                    width="25"
                                    height="25"
                                    href="https://support.buyerbridge.com/knowledge/how-to-setup-dependencies-for-microsoft-automotive-inventory-ads-aia#create-microsoft-catalog"
                                    target="_blank"
                                    icon="book"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        GUIDE
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="url-field">
                        <p class="desc mb-2">
                            During setup you'll need the feed URL below:
                        </p>
                        <div class="field-holder">
                            <action-button
                                v-if="urlLoading"
                                size="35"
                                icon=""
                                :loading="true" />
                            <v-text-field
                                v-else
                                ref="urlInput"
                                v-model="url"
                                readonly
                                class="styled-field" />
                            <action-button
                                v-if="!copied"
                                class="copy"
                                width="27"
                                height="27"
                                icon="copy"
                                @click.prevent="copy" />
                            <span
                                v-else
                                class="copied">Copied!</span>
                        </div>
                    </div>
                    <a
                        href="#"
                        class="trouble"
                        @click.prevent="$store.dispatch('createOnboardingSupportTicket')">Having trouble? Connect with support</a>
                </div>
            </div>
            <div class="button-wrapper">
                <styled-button
                    v-if="submitInProgress"
                    class="styled-button"
                    :disabled="true"
                    :loading="submitInProgress"
                    @click.prevent="submit">
                    WAIT...
                </styled-button>
                <styled-button
                    v-else
                    class="styled-button"
                    @click.prevent="submit">
                    CONTINUE
                </styled-button>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ActionButton from '@/components/globals/ActionButton';
import StyledButton from '@/components/globals/StyledButton';
import validationRules from '@/helpers/validationRules';
import Loader from '@/components/globals/Loader';
import StatusIcon from '@/components/globals/StatusIcon';
import { PLATFORM_ID_MICROSOFT } from '@/helpers/globals';
import colors from '@/helpers/colors.js';

    export default {
        components: {
            ActionButton,
            StyledButton,
            Loader,
            StatusIcon
        },
        data() {
            return {
                colors,
                requiredRules: validationRules.requiredRules,
                formValid: false,
                accountId: '',
                catalogId: '',
                pixelId: '',
                url: '',
                urlLoading: true,
                copied: false,
                loading: false,
                errorMessage: '',
                submitInProgress: false,
            };
        },
        computed: {
            ...mapState({
                onboardingProducts: (state) => state.onboarding.products,
                dealer: (state) => state.dealer.currentDealer,
            }),
            ...mapGetters([
                'dealerFeaturesComplete',
                'requiredFeatures'
            ]),
            hasAdAccountSetup() {
                return this.requiredFeatures.includes('microsoft_ad_account');
            },
            adAccountComplete() {
                return this.dealerFeaturesComplete.includes('microsoft_ad_account');
            },
            hasCatalogSetup() {
                return this.requiredFeatures.includes('microsoft_catalog');
            },
            catalogComplete() {
                return this.dealerFeaturesComplete.includes('microsoft_catalog');
            },
            hasPixelSetup() {
                return this.requiredFeatures.includes('microsoft_pixel');
            },
            pixelComplete() {
                return this.dealerFeaturesComplete.includes('microsoft_pixel');
            },
            allAssetsSetup() {
                return (
                    (this.hasAdAccountSetup ? this.adAccountComplete : true)
                    && (this.hasCatalogSetup ? this.catalogComplete : true)
                    && (this.hasPixelSetup ? this.pixelComplete : true)
                );
            },
            valid() {
                return (this.catalogId && this.accountId && this.pixelId);
            },
            existingAdAccount() {
                return this.dealer.ad_accounts.data.find(a => a.platform_id == PLATFORM_ID_MICROSOFT) ?? null;
            },
            existingCatalog() {
                return this.dealer.catalogs.data.find(c => c.platform_id == PLATFORM_ID_MICROSOFT) ?? null;
            },
            existingPixel() {
                return this.dealer.pixels.data.find(p => p.platform_id == PLATFORM_ID_MICROSOFT) ?? null;
            },
        },
        async mounted() {
            await this.initAll();
        },
        methods: {
            async initAll() {
                this.checkFeatureStatus();
                await this.initGeneratingRemoteFiles();

                this.loading = true;
                this.accountId = this.existingAdAccount?.external_ad_account_id ?? '';
                this.catalogId = this.existingCatalog?.external_catalog_id ?? '';
                this.pixelId = this.existingPixel?.external_pixel_id ?? '';
                this.loading = false;

                await this.getURL();
            },
            async initGeneratingRemoteFiles() {
                const fileId = {
                    export_format_ids: []
                };
                if (this.onboardingProducts[0].platform_id == PLATFORM_ID_MICROSOFT) {
                    fileId.export_format_ids = [12]; // microsoft_auto_csv
                }
                try {
                    await this.$http.post(`/dealers/${this.dealer.id}/upload-exports`, fileId);
                } catch(error) {
                    console.log(error);
                }
            },
            async getURL() {
                try {
                    this.urlLoading = true;
                    const response = await this.$http.get(`/dealers/${this.dealer.id}/get-remote-files`);
                    const remoteFiles = response.data;
                    remoteFiles.forEach(file =>{
                        if (file.remote_file_type.name.includes('Bing')) {
                            this.url = file.url;
                        }
                    });
                } catch(error) {
                    console.log(error);
                } finally {
                    if (this.url) {
                        this.urlLoading = false;
                    } else {
                        setTimeout(() => {
                            this.getURL();
                        }, 10000);
                    }
                }
            },
            async createNewAdAccount() {
                const adAccountData = {
                    dealer_id: this.dealer.id,
                    platform_id: PLATFORM_ID_MICROSOFT,
                    external_ad_account_id: this.accountId,
                };
                try {
                    await this.$http.post('/ad_accounts', adAccountData);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async createNewCatalog() {
                const catalogData = {
                    dealer_id: this.dealer.id,
                    platform_id: PLATFORM_ID_MICROSOFT,
                    external_catalog_id: this.catalogId,
                };
                try {
                    await this.$http.post('/catalogs', catalogData);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async createNewPixel() {
                const pixelData = {
                    dealer_id: this.dealer.id,
                    platform_id: PLATFORM_ID_MICROSOFT,
                    external_pixel_id: this.pixelId,
                };
                try {
                    await this.$http.post('/pixels', pixelData);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async sendAllData() {
                if (!this.adAccountComplete && this.accountId) {
                    await this.createNewAdAccount();
                }

                if (!this.catalogComplete && this.catalogId) {
                    await this.createNewCatalog();
                }

                if (!this.pixelComplete && this.pixelId) {
                    await this.createNewPixel();
                }

                await this.checkFeatureStatus();
            },
            checkFeatureStatus() {
                return this.$store.dispatch('updateOnboardingStatus', {
                    features: [
                        'microsoft_ad_account',
                        'microsoft_catalog',
                        'microsoft_pixel'
                    ]
                });
            },
            async submit() {
                this.submitInProgress = true;
                this.errorMessage = '';
                await this.sendAllData();
                this.submitInProgress = false;
                if (this.errorMessage) {
                    return;
                }
                this.$store.dispatch('goToNextStep');
            },
            copy() {
                const copyText = this.$refs.urlInput;
                navigator.clipboard.writeText(copyText.value).then(() => {
                    this.copied = true;
                }).catch(err => {
                    console.log('Something went wrong with copying:', err);
                });
            }
        }
    };
</script>

<style lang="scss">
#build-details-form{
    .top{
        display: flex;
        justify-content: space-between;
        &__right{
            padding: 30px;
            min-width: 130px;
        }
    }
    .desc{
        font-size:14px;
    }
    .build-details{
        padding: 30px;
        margin-top: 15px;
        border-top: 1px solid $gray-light;
    }
    .dark-word{
        color:$gray-dark;
        font-size: 10px;
        line-height: 14px;
    }
    .icon{
        margin-left: 30px;
    }
    .video-icon{
        path{
            transform: translateY(-2px);
        }
    }
    .block{
        //justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        &__icons{
            white-space: nowrap;
            width: 20%;
        }
        &__info{
            width: 100%;
            margin-bottom: 50px;
            @media (min-width: $bp-lg) {
                width: 40%;
                margin-right: 5%;
                margin-bottom: 0;
            }
        }
        &__select{
            width: 30%;
            margin-right: 5%;
            //width: 220px;
            //margin-left: 60px;
            .v-select__selections{
                min-height: 40px;
                //width: 220px;
                overflow: hidden;
            }
        }
    }
    .button-wrapper{
        text-align: right;
        padding: 0 30px;
    }
    .styled-button{
        font-size: 14px;
    }
    .trouble{
        margin-top:40px;
        display: inline-block;
        font-size: 14px;
    }
    .url-field{
        margin-top:30px;
        max-width: 400px;
        input{
            padding-top:14px;
            padding-bottom:14px;
        }
    }
    .field-holder{
        display: flex;
    }
    .copy{
        margin-left: 15px;
        transform: translateY(2px);
    }
    .copied{
        color: green;
        margin-left: 10px;
        font-size: 14px;
        margin-top:10px;
    }
    .err-message{
        padding: 0 30px;
        margin-bottom: 30px;
        text-align: center;
        color: red;
    }
    .sp{
        padding-top:2px;
    }
}
.small-desc {
    font-size: 9px;
    margin: 3px;
    color: $gray;
}
</style>